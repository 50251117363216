@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../image/Practical/img.jpg);
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: cover;
    background-attachment: fixed;
    background-color: transparent;
    position: relative;
    padding: 0;
}

.container{
    @include main-column;
    background-color: rgba($background, .7);
}

.box{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}

.title{
    @include title;
    text-transform: uppercase;
}

.box_image{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    margin: 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.image{
    max-width: 33%;
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
}


@media(max-width: 600px){
    .title{
        max-width: 400px;
    }
}

@media(max-width: 425px){
    .title{
        max-width: 320px;
    }
    .box_image{
        & :last-child{
            display: none;
        }
    }

    .image{
        max-width: 49%;
    }
}