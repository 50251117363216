@import '../../base.scss';

@keyframes wheel-rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.main{
    @include main-column;
}

.box{
    @include box-column;
}

.container{
    @include box-column;
}

.title{
    @include title
}

.box_line{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 30px
}

.line{
    max-width: 300px;
    width: 100%;
    height: 1px;
    background-color: $title;
}

.wheel{
    width: 30px;
    height: 30px;
    margin: 0 20px;
    animation: wheel-rotation 1s linear infinite;
}

.list{
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    padding: 0 20px;
}

@media(max-width: 768px){
    .list{
        padding: 0 10px;
    }
}

