@import '../../base.scss';

.item{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 650px;
    overflow: hidden;
    aspect-ratio: 4/3;

    &:hover{
        transition: all .3s;
        cursor: pointer;
        filter: contrast(60%);
        
    }
}

.image{
    width: 100%;
    height: 100%;
	vertical-align: middle;
	display: inline-block;
    object-fit: cover;
}