@import '../../base.scss';

@keyframes wheel-rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    padding-bottom: 30px
}

.title{
    @include title;
}

.box_line{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 30px
}

.line{
    max-width: 300px;
    width: 100%;
    height: 1px;
    background-color: $title;
}

.wheel{
    width: 30px;
    height: 30px;
    margin: 0 20px;
    animation: wheel-rotation 1s linear infinite;
}

.list{
    @include list;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0 10px 20px;
    position: relative;
    border-left: 2px solid rgba($color: $element, $alpha: .3);
    color: $title;
}

.number{
    font-size: 60px;
    line-height: 1.2;
    font-weight: 700;
    color: $element;
    opacity: .5;
    margin: 0;
}

.item_title{
    font-weight: 700;
    font-size: 20px;
    height: 48px;
    line-height: 1.2;
    text-align: left;
    margin: 10px 0 0;
}

.item_text{
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
}
