@import '../../base.scss';

@keyframes wheel-rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.main{
    @include main-column;
}

.box{
    box-sizing: border-box;
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title
}

.box_line{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 30px
}

.line{
    max-width: 300px;
    width: 100%;
    height: 1px;
    background-color: $title;
}

.wheel{
    width: 30px;
    height: 30px;
    margin: 0 20px;
    animation: wheel-rotation 1s linear infinite;
}

.list{
    max-width: 1200px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
    margin-bottom: 30px;
}

.button{
    @include button;
}