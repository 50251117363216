@import '../../base.scss';

@keyframes wheel-rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
}

.container{
    @include main-column;
}

.title{
    @include title;
}

.box_line{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 30px
}

.line{
    max-width: 300px;
    width: 100%;
    height: 1px;
    background-color: $title;
}

.wheel{
    width: 30px;
    height: 30px;
    margin: 0 20px;
    animation: wheel-rotation 1s linear infinite;
}

.list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba( $black, .6 );
    padding: 20px 10px;
    position: relative;
    z-index: 1;
    border-radius: 6px;
    color: $title;

    &:hover{
        cursor: pointer;
        transform:scale(1.05);
        transition: .5s;
        background-color: $background-footer;
        color: $white;
    }
}

.box_image{
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $element;
}

.icon{
    width: 50px;
    height: 50px;
}

.item_title{
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    text-align: center;
    margin: 20px 0 0;
}

.item_text{
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    margin: 10px 0 0;
}


.button{
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    color: $title;
    max-width: 350px;
    width: 100%;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 4px;
    background: $element;
    z-index: 1;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: .5s;
        box-shadow: 0px 0px 8px 4px rgba($element, 0.25), 0px 0px 4px 4px rgba($element, 0.25);
    }
}

.image{
    width: 350px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 1%;
}


@media(max-width: 900px){
    button{
        max-width: 250px;
    }
    
}

@media(max-width: 768px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .image{
        width: 250px;
    }
}